.login {
  display: flex;
  background-color: white;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.login__container {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid lightgray;
}

.login__logo {
  width: 100px;
  object-fit: contain;
  margin: 20px 0;
}

.login__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.login__container > form > h5 {
  margin-bottom: 5px;
}

.login__container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}

.login__container > p {
  margin: 15px 0;
  font-size: 12px;
}

.login__container > form > button {
  background-color: #f0c14b;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  color: #111;
}

.login__signInButton,
.login__registerButton {
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  background-color: #f0c14b;
}

.login__signInGoogleIcon {
  text-align: center;
  width: 30px;
  object-fit: contain;
}

.login__signInGoogleButton {
  text-align: center;
}
