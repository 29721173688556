.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-height: 400px;
  min-width: 100px;
  margin: 10px;
  padding: 20px;
  width: 100%;
  z-index: 1;
  background-color: white;
}

.product__info {
  height: 100px;
}

.product__info > p {
  text-align: center;
}

.product__group {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.product__price {
  margin-top: 3px;
  padding-right: 10px;
}

.product__rating {
  display: flex;
  color: #f0c14b;
}

.product > img {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 15px;
  transition: all 0.5s;
}

.product > img:hover {
  transform: scale(1.05);
}

.product > button {
  background-color: #f0c14b;
  border: 1px solid;
  border-color: #a88734 #9c7e31 #846a29;
  margin-top: 5px;
  padding: 5px 10px;
  width: 100%;
}
