.checkoutProduct {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.5s ease-in-out;
}

.checkoutProduct__image {
  object-fit: contain;
  width: 180px;
  height: 180px;
  transition: all 0.5s;
}

.checkoutProduct__image:hover {
  transform: scale(1.05);
}

.checkoutProduct__info {
  padding-left: 20px;
}

.checkoutProduct__info > button {
  background-color: #f0c14b;
  border: 1px solid;
  border-color: #a88734 #9c7e31 #846a29;
  margin-top: 20px;
  padding: 5px 10px;
  transition: all 0.1s ease-in-out;
}

.checkoutProduct__info > button:hover {
  transform: scale(1.04);
}

.checkoutProduct__title {
  font-size: 17px;
  font-weight: 800;
}

.checkoutProduct__price {
  font-size: 24px;
  margin-top: 10px;
}

.checkoutProduct__rating {
  display: flex;
  color: #f0c14b;
  margin-top: 20px;
}
